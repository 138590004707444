import React from 'react';
import { withNamespaces } from 'react-i18next';
import ContentWrapper from '../../Layout/ContentWrapper';
import ReactMapGL, { Marker, Popup } from 'react-map-gl';
import { Link } from 'react-router-dom';
//import ReactTooltip from 'react-tooltip';  library hover everywhere

class Dashboard extends React.Component {

    state = {
        viewport: {
            width: 1200,
            height: 600,
            latitude: 42.667542,
            longitude: 21.166191,
            zoom: 15,
            dropdownOpen: false
        },
        lat: 42.667542,
        lon: 21.166191,
        popupInfo: "",
    };

    changeLanguage = lng => {
        this.props.i18n.changeLanguage(lng);
    }

    toggle = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    _renderPopup() {
        const { popupInfo } = this.state;

        return popupInfo && (
            <Popup tipSize={5}
                anchor="bottom-right"
                longitude={this.state.lon}
                latitude={this.state.lat}
                closeOnClick={false} offsetLeft={-10} offsetTop={-46}
                onClose={() => this.setState({ popupInfo: "" })} >
                <div>{popupInfo}</div>
            </Popup>
        );
    }

    render() {

        const _id = '5c9b839391d8197249d6909a';
        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div>Dashboard
                    </div>
                </div>
                <ReactMapGL
                    {...this.state.viewport}
                    mapStyle={'mapbox://styles/mapbox/streets-v9'}
                    mapboxApiAccessToken={'pk.eyJ1IjoiYWhtZXRoYWxpbWkiLCJhIjoiY2pzb3Blejd3MG12eTQzanZmZ2JlMWVncCJ9.AdkOn5ymrllYZh0OGHXVmw'}
                    width={"100%"}
                    height={565}
                    //latitude={42.669542} static 
                    //longitude={21.165191}                
                    onViewportChange={(viewport) => {
                        // const { width, height, latitude, longitude, zoom } = viewport;
                        this.setState({ viewport: viewport })
                        // Optionally call `setState` and use the state to update the map.
                    }}
                >
                    <Marker latitude={this.state.lat} longitude={this.state.lon} offsetLeft={-23} offsetTop={-46} >

                        <div
                            onMouseOver={() => { this.setState({ popupInfo: "You are here" }) }}
                            onMouseLeave={() => { this.setState({ popupInfo: "" }) }} >

                            <Link to={`/editPointInterest/${_id}`}>
                                <img src="img/pin.png" width={46} height={46} alt="App Logo" ></img>
                            </Link>
                        </div>
                    </Marker>
                    {this._renderPopup()}
                </ReactMapGL>
            </ContentWrapper>
        );
    }
}

export default withNamespaces('translations')(Dashboard);