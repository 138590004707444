const Menu = [
  {
    heading: "Main Navigation",
    translate: "sidebar.heading.HEADER",
    user_access: [1, 2, 3],
  },
  {
    name: "Dashboard",
    path: "/dashboard",
    icon: "icon-grid",
    translate: "sidebar.nav.DASHBOARD",
    user_access: [1],
  },
  {
    name: "Points of Interest",
    path: "/pointsinterest",
    icon: "icon-bulb",
    translate: "sidebar.nav.POINTSOFINTEREST",
    user_access: [1],
  },
  {
    name: "Buses",
    path: "/buses",
    icon: "fas fa-bus",
    translate: "sidebar.nav.BUSES",
    user_access: [1, 2],
  },
  {
    name: "Zones",
    path: "/zones",
    icon: "icon-location-pin",
    translate: "sidebar.nav.ZONES",
    user_access: [1],
  },
  {
    name: "Lines",
    path: "/lines",
    icon: "icon-direction",
    translate: "sidebar.nav.LINES",
    user_access: [1, 2],
  },
  {
    name: "Stations",
    path: "/stations",
    icon: "icon-location-pin",
    translate: "sidebar.nav.STATIONS",
    user_access: [1, 2],
  },
  {
    name: "Arrangement",
    path: "/schedules",
    icon: "icon-settings",
    translate: "sidebar.nav.SCHEDULES",
    user_access: [1, 2],
  },
  {
    name: "News",
    path: "/news",
    icon: "far fa-newspaper",
    translate: "sidebar.nav.SCHEDULES",
    user_access: [1, 2],
  },
  {
    name: "Out of service area",
    path: "/outOfServiceArea",
    icon: "fas fa-times",
    translate: "sidebar.nav.SCHEDULES",
    user_access: [1],
  },
  {
    name: "Reports",
    path: "/report",
    icon: "icon-info",
    translate: "sidebar.nav.SCHEDULES",
    user_access: [1, 2],
  },
  {
    name: "Advertisements",
    icon: "fab fa-adversal",
    translate: "sidebar.nav.SCHEDULES",
    user_access: [1],
    submenu: [
      {
        name: "Campaigns",
        path: "/campaign",
      },
      {
        name: "Map advertisements",
        path: "/pinAdvertisements",
      },
      {
        name: "Station advertisements",
        path: "/advertisements",
      },
    ],
  },
  {
    name: "Notifications",
    path: "/notifications",
    icon: "fa fa-bell",
    translate: "sidebar.nav.SCHEDULES",
    user_access: [1, 2],
  },
];

export default Menu;
