import { TOKEN } from '../actions/actions';

function token(state = ["meti"], action){
    switch(action.type) {
        case TOKEN:
            return "tokennnnnn";
        default:
            return state;
    }
}

export default token;