/*!
 *
 * Angle - Bootstrap Admin Template
 *
 * Version: 4.2.1
 * Author: @themicon_co
 * Website: http://themicon.co
 * License: https://wrapbootstrap.com/help/licenses
 *
 */

import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { BrowserRouter } from 'react-router-dom';

// App Routes
import Routes from './Routes';

// Vendor dependencies
import "./Vendor";
// Application Styles
import './styles/bootstrap.scss';
import './styles/app.scss'
import config from './config'
import io from 'socket.io-client';
import pubsub from "pubsub-js";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
var jwtDecode = require('jwt-decode');

class App extends Component {

  socket = null

  componentWillMount() {
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    axios.defaults.baseURL = config.BASE_PATH

    this.socket = io(axios.defaults.baseURL.replace('/api/private', '').replace('https://', 'wss://').replace('http://', 'ws://'), {path: '/ws/socket.io',});
    this.socket.on('bus_position_update', (data)=>{
      pubsub.publish('bus_position_update', data)
      // console.log(data)
    })

  }


  render() {
    const token = localStorage.getItem('token')
    axios.defaults.baseURL = config.BASE_PATH
    axios.interceptors.response.use(response => {
      return response
    }, error=>{
      if(error.response && error.response.status == 401){
        localStorage.clear()
        window.location.pathname = '/login';
      }else{
        throw error
      }
    })
    if(token){
      // this.props.userStore.setUserLevel(decodedToken.user_level);
      axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
    }
    // else{
    //   window.location.pathname = '/login';
    // }
    //Redirect to login
    // if( window.location.pathname!='/login'){
    //   window.location.pathname = '/login';
    // }

    pubsub.subscribe('showNotification', (topic, data) => {
      toast(data.message, {
        type:data.type,
        position: 'top-right'
    })
  });

    // specify base href from env varible 'PUBLIC_URL'
    // use only if application isn't served from the root
    // for development it is forced to root only
    /* global PUBLIC_URL */
    const basename = process.env.NODE_ENV === 'development' ? '/' : (PUBLIC_URL || '/');

    return (
        <Fragment>
          <ToastContainer />
          <BrowserRouter basename={basename}>
            <Routes />
        </BrowserRouter>
        </Fragment>
        
    );

  }
}

export default App;
