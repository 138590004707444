import { combineReducers } from 'redux';

import settingsReducer from './settings.reducer.js';
import themesReducer from './themes.reducers.js';
import token from './token.reducers';

export default combineReducers({
    settings: settingsReducer,
    theme: themesReducer,
    token
});
