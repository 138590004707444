import React, { Suspense, lazy } from "react";
import { withRouter, Switch, Route, Redirect } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

/* loader component for Suspense*/
import PageLoader from "./components/Common/PageLoader";

import Base from "./components/Layout/Base";
import BasePage from "./components/Layout/BasePage";
import Dashboard from "./components/Screen/Dashboard/Dashboard";
// import BaseHorizontal from './components/Layout/BaseHorizontal';

/* Used to render a lazy component with react-router */
const waitFor = (Tag) => (props) => <Tag {...props} />;

const PointInterest = lazy(() =>
  import("./components/Screen/PointInterest/PointInterest")
);
const EditPointInterest = lazy(() =>
  import("./components/Screen/PointInterest/EditPointInterest")
);
const Buses = lazy(() => import("./components/Screen/Buses/Buses"));
const EditBuses = lazy(() => import("./components/Screen/Buses/EditBuses"));
const Lines = lazy(() => import("./components/Screen/Lines/Lines"));
const EditLines = lazy(() => import("./components/Screen/Lines/EditLines"));
const Stations = lazy(() => import("./components/Screen/Stations/Stations"));
const EditStation = lazy(() =>
  import("./components/Screen/Stations/EditStation")
);
const Schedules = lazy(() => import("./components/Screen/Schedules/schedules"));
const Zones = lazy(() => import("./components/Screen/Zones/Zones"));
const EditZones = lazy(() => import("./components/Screen/Zones/EditZones"));
const News = lazy(() => import("./components/Screen/News/News"));
const EditNews = lazy(() => import("./components/Screen/News/EditNews"));
const OutOfServiceArea = lazy(() =>
  import("./components/Screen/OutOfServiceArea/OutOfServiceArea")
);
const EditOutOfServiceArea = lazy(() =>
  import("./components/Screen/OutOfServiceArea/EditOutOfServiceArea")
);
const Login = lazy(() => import("./components/Pages/Login"));
const Report = lazy(() => import("./components/Screen/Raports/Report"));
const EditReport = lazy(() => import("./components/Screen/Raports/EditReport"));
const Advertisements = lazy(() =>
  import("./components/Screen/Advertisements/Advertisements")
);
const EditAdvertisement = lazy(() =>
  import("./components/Screen/Advertisements/EditAdvertisement")
);
const Campaign = lazy(() => import("./components/Screen/Campaign/Campaign"));
const EditCampaign = lazy(() =>
  import("./components/Screen/Campaign/EditCampaign")
);
const EditPinAdvertisements = lazy(() =>
  import("./components/Screen/PinAdvertisements/EditPinAdvertisements")
);
const PinAdvertisements = lazy(() =>
  import("./components/Screen/PinAdvertisements/PinAdvertisements")
);
const Notifications = lazy(() =>
  import("./components/Screen/Notifications/Notifications")
);
const NotificationsEdit = lazy(() =>
  import("./components/Screen/Notifications/NotificationsEdit")
);

// List of routes that uses the page layout
// listed here to Switch between layouts
// depending on the current pathname

const listofPages = [
  "/login",
  /* See full project for reference */
];

const Routes = ({ location }) => {
  const currentKey = location.pathname.split("/")[1] || "/";
  const timeout = { enter: 500, exit: 500 };

  // Animations supported
  //      'rag-fadeIn'
  //      'rag-fadeInUp'
  //      'rag-fadeInDown'
  //      'rag-fadeInRight'
  //      'rag-fadeInLeft'
  //      'rag-fadeInUpBig'
  //      'rag-fadeInDownBig'
  //      'rag-fadeInRightBig'
  //      'rag-fadeInLeftBig'
  //      'rag-zoomBackDown'
  const animationName = "rag-fadeIn";

  if (listofPages.indexOf(location.pathname) > -1) {
    return (
      // Page Layout component wrapper
      <BasePage>
        <Suspense fallback={<PageLoader />}>
          <Switch location={location}>
            <Route path="/login" component={Login} />
            {/* See full project for reference */}
          </Switch>
        </Suspense>
      </BasePage>
    );
  } else {
    const token = localStorage.getItem("token");
    const user_level = parseInt(localStorage.getItem("user_level"));

    if (token == null || typeof token == "undefined") {
      return (
        <Base>
          <TransitionGroup>
            <CSSTransition
              key={currentKey}
              timeout={timeout}
              classNames={animationName}
              exit={false}
            >
              <div>
                <Suspense fallback={<PageLoader />}>
                  <Switch location={location}>
                    <Route path="/dashboard" component={waitFor(Dashboard)} />
                    <Route
                      path="/pointsInterest"
                      component={waitFor(PointInterest)}
                    />
                    <Route
                      path="/editpointInterest/:pointId"
                      component={waitFor(EditPointInterest)}
                    />
                    <Route
                      path="/editpointInterest"
                      component={waitFor(EditPointInterest)}
                    />
                    <Route path="/buses" component={waitFor(Buses)} />
                    <Route
                      path="/editBuses/:busId"
                      component={waitFor(EditBuses)}
                    />
                    <Route path="/editBuses" component={waitFor(EditBuses)} />
                    <Route
                      path="/notificationsEdit"
                      component={waitFor(NotificationsEdit)}
                    />
                    <Route path="/lines" component={waitFor(Lines)} />
                    <Route
                      path="/editLines/:lineId"
                      component={waitFor(EditLines)}
                    />
                    <Route path="/editLines" component={waitFor(EditLines)} />
                    <Route path="/stations" component={waitFor(Stations)} />
                    <Route
                      path="/editStation/:stationId"
                      component={waitFor(EditStation)}
                    />
                    <Route
                      path="/editStation"
                      component={waitFor(EditStation)}
                    />
                    <Route path="/schedules" component={waitFor(Schedules)} />
                    <Route
                      path="/editOutOfServiceArea/:outServiceId"
                      component={waitFor(EditOutOfServiceArea)}
                    />
                    <Route
                      path="/editOutOfServiceArea"
                      component={waitFor(EditOutOfServiceArea)}
                    />
                    <Route
                      path="/outOfServiceArea"
                      component={waitFor(OutOfServiceArea)}
                    />
                    <Route path="/zones" component={waitFor(Zones)} />
                    <Route
                      path="/editZones/:zoneId"
                      component={waitFor(EditZones)}
                    />
                    <Route path="/editZones" component={waitFor(EditZones)} />
                    <Route
                      path="/editNews/:newsId"
                      component={waitFor(EditNews)}
                    />
                    <Route path="/editNews" component={waitFor(EditNews)} />
                    <Route path="/news" component={waitFor(News)} />
                    <Route
                      path="/editReport/:reportId"
                      component={waitFor(EditReport)}
                    />
                    <Route path="/editReport" component={waitFor(EditReport)} />
                    <Route path="/report" component={waitFor(Report)} />
                    <Route
                      path="/advertisements"
                      component={waitFor(Advertisements)}
                    />
                    <Route
                      path="/notifications"
                      component={waitFor(Notifications)}
                    />
                    <Route
                      path="/editAdvertisement/:adversId"
                      component={waitFor(EditAdvertisement)}
                    />
                    <Route
                      path="/editAdvertisement"
                      component={waitFor(EditAdvertisement)}
                    />
                    <Route path="/campaign" component={waitFor(Campaign)} />
                    <Route
                      path="/editCampaign/:campaignId"
                      component={waitFor(EditCampaign)}
                    />
                    <Route
                      path="/editCampaign"
                      component={waitFor(EditCampaign)}
                    />
                    <Route
                      path="/pinAdvertisements"
                      component={waitFor(PinAdvertisements)}
                    />
                    <Route
                      path="/editPinAdvertisements/:pinId"
                      component={waitFor(EditPinAdvertisements)}
                    />
                    <Route
                      path="/editPinAdvertisements"
                      component={waitFor(EditPinAdvertisements)}
                    />
                    {!token ? (
                      <Redirect to="/login" />
                    ) : (
                      <Redirect to="/dashboard" />
                    )}
                  </Switch>
                </Suspense>
              </div>
            </CSSTransition>
          </TransitionGroup>
        </Base>
      );
    } else {
      if (user_level == 1) {
        // Pages allowed for user_level 1
        return (
          <Base>
            <TransitionGroup>
              <CSSTransition
                key={currentKey}
                timeout={timeout}
                classNames={animationName}
                exit={false}
              >
                <div>
                  <Suspense fallback={<PageLoader />}>
                    <Switch location={location}>
                      <Route path="/dashboard" component={waitFor(Dashboard)} />
                      <Route
                        path="/pointsInterest"
                        component={waitFor(PointInterest)}
                      />
                      <Route
                        path="/editpointInterest/:pointId"
                        component={waitFor(EditPointInterest)}
                      />
                      <Route
                        path="/editpointInterest"
                        component={waitFor(EditPointInterest)}
                      />
                      <Route path="/buses" component={waitFor(Buses)} />
                      <Route
                        path="/editBuses/:busId"
                        component={waitFor(EditBuses)}
                      />
                      <Route path="/editBuses" component={waitFor(EditBuses)} />
                      <Route path="/lines" component={waitFor(Lines)} />
                      <Route
                        path="/editLines/:lineId"
                        component={waitFor(EditLines)}
                      />
                      <Route path="/editLines" component={waitFor(EditLines)} />
                      <Route path="/stations" component={waitFor(Stations)} />
                      <Route
                        path="/editStation/:stationId"
                        component={waitFor(EditStation)}
                      />
                      <Route
                        path="/editStation"
                        component={waitFor(EditStation)}
                      />
                      <Route path="/schedules" component={waitFor(Schedules)} />
                      <Route
                        path="/editOutOfServiceArea/:outServiceId"
                        component={waitFor(EditOutOfServiceArea)}
                      />
                      <Route
                        path="/editOutOfServiceArea"
                        component={waitFor(EditOutOfServiceArea)}
                      />
                      <Route
                        path="/outOfServiceArea"
                        component={waitFor(OutOfServiceArea)}
                      />
                      <Route path="/zones" component={waitFor(Zones)} />
                      <Route
                        path="/editZones/:zoneId"
                        component={waitFor(EditZones)}
                      />
                      <Route path="/editZones" component={waitFor(EditZones)} />
                      <Route
                        path="/editNews/:newsId"
                        component={waitFor(EditNews)}
                      />
                      <Route path="/editNews" component={waitFor(EditNews)} />
                      <Route path="/news" component={waitFor(News)} />
                      <Route
                        path="/editReport/:reportId"
                        component={waitFor(EditReport)}
                      />
                      <Route
                        path="/editReport"
                        component={waitFor(EditReport)}
                      />
                      <Route path="/report" component={waitFor(Report)} />
                      <Route
                        path="/advertisements"
                        component={waitFor(Advertisements)}
                      />
                      <Route
                        path="/notifications"
                        component={waitFor(Notifications)}
                      />
                      <Route
                        path="/notificationsEdit"
                        component={waitFor(NotificationsEdit)}
                      />
                      <Route
                        path="/editAdvertisement/:adversId"
                        component={waitFor(EditAdvertisement)}
                      />
                      <Route
                        path="/editAdvertisement"
                        component={waitFor(EditAdvertisement)}
                      />
                      <Route path="/campaign" component={waitFor(Campaign)} />
                      <Route
                        path="/editCampaign/:campaignId"
                        component={waitFor(EditCampaign)}
                      />
                      <Route
                        path="/editCampaign"
                        component={waitFor(EditCampaign)}
                      />
                      <Route
                        path="/pinAdvertisements"
                        component={waitFor(PinAdvertisements)}
                      />
                      <Route
                        path="/editPinAdvertisements/:pinId"
                        component={waitFor(EditPinAdvertisements)}
                      />
                      <Route
                        path="/editPinAdvertisements"
                        component={waitFor(EditPinAdvertisements)}
                      />
                      {!token ? (
                        <Redirect to="/login" />
                      ) : (
                        <Redirect to="/dashboard" />
                      )}
                    </Switch>
                  </Suspense>
                </div>
              </CSSTransition>
            </TransitionGroup>
          </Base>
        );
      } else if (user_level == 2) {
        // Pages allowed for user_level 2
        return (
          <Base>
            <TransitionGroup>
              <CSSTransition
                key={currentKey}
                timeout={timeout}
                classNames={animationName}
                exit={false}
              >
                <div>
                  <Suspense fallback={<PageLoader />}>
                    <Switch location={location}>
                      <Route path="/schedules" component={waitFor(Schedules)} />
                      <Route
                        path="/editNews/:newsId"
                        component={waitFor(EditNews)}
                      />
                      <Route path="/editNews" component={waitFor(EditNews)} />
                      <Route path="/news" component={waitFor(News)} />
                      <Route path="/buses" component={waitFor(Buses)} />
                      <Route
                        path="/editBuses/:busId"
                        component={waitFor(EditBuses)}
                      />
                      <Route
                        path="/notificationsEdit"
                        component={waitFor(NotificationsEdit)}
                      />
                      <Route path="/editBuses" component={waitFor(EditBuses)} />
                      <Route path="/lines" component={waitFor(Lines)} />
                      <Route
                        path="/editLines/:lineId"
                        component={waitFor(EditLines)}
                      />
                      <Route path="/editLines" component={waitFor(EditLines)} />
                      <Route
                        path="/editReport/:reportId"
                        component={waitFor(EditReport)}
                      />
                      <Route
                        path="/editReport"
                        component={waitFor(EditReport)}
                      />
                      <Route path="/report" component={waitFor(Report)} />
                      <Route path="/stations" component={waitFor(Stations)} />
                      {!token ? (
                        <Redirect to="/login" />
                      ) : (
                        <Redirect to="/schedules" />
                      )}
                    </Switch>
                  </Suspense>
                </div>
              </CSSTransition>
            </TransitionGroup>
          </Base>
        );
      } else {
        return (
          <Base>
            <TransitionGroup>
              <CSSTransition
                key={currentKey}
                timeout={timeout}
                classNames={animationName}
                exit={false}
              >
                <div>
                  <Suspense fallback={<PageLoader />}>
                    <Switch location={location}>
                      {!token ? (
                        <Redirect to="/login" />
                      ) : (
                        <Redirect to="/dashboard" />
                      )}
                    </Switch>
                  </Suspense>
                </div>
              </CSSTransition>
            </TransitionGroup>
          </Base>
        );
      }
    }
  }
};

export default withRouter(Routes);
